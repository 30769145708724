import { render, staticRenderFns } from "./OrganizationEcomUpdate.vue?vue&type=template&id=039e0f5d&scoped=true"
import script from "./OrganizationEcomUpdate.vue?vue&type=script&lang=js"
export * from "./OrganizationEcomUpdate.vue?vue&type=script&lang=js"
import style0 from "./OrganizationEcomUpdate.vue?vue&type=style&index=0&id=039e0f5d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039e0f5d",
  null
  
)

export default component.exports